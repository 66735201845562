<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="title">账户管理</span>
      </div>
      <div class="cardContent">
        <el-row :gutter="20">
          <el-col :span="4">
            <div class="cardContentL">
              <div class="personInfo">
                <div class="avatar"><img :src="userInfo.headPic" /></div>
                <div>
                  <div class="nickname">{{ userName }}</div>
                  <div class="tel">{{ userInfo.phone }}</div>
                  <div v-if="superAdminDetail.superAdmin" class="admin" @click="changeAdmin">
                    更换超级管理员
                  </div>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="20">
            <div class="cardContentR">
              <div v-if="!bankDetail.show" class="top">
                <div>还没有固定交易账号 <i class="el-icon-question"></i></div>
                <div class="bankCard">
                  <el-button
                    v-if="superAdminDetail.superAdmin"
                    type="primary"
                    size="mini"
                    class="bankCardBtn"
                    @click="setBank"
                    >设置银行卡</el-button
                  >
                </div>
              </div>
              <div v-else class="tops">
                <div class="topsWrap">
                  <div>
                    固定交易账号
                    <el-popover
                      placement="top-start"
                      width="300"
                      trigger="hover"
                      content="固定交易账号的作用主要是确保交易的唯一性和安全性，平台所有和您的交易只会通过该账号完成，提现打款只会打入到该账号"
                    >
                      <i slot="reference" class="el-icon-question"></i>
                    </el-popover>
                  </div>
                  <div class="bankCardNum">
                    <div>{{ bankDetail.bankNumber }}</div>
                    <div class="arrowDown" @click="isShowBank">
                      <i v-if="isShowStatus" class="el-icon-arrow-down"></i>
                      <i v-else class="el-icon-arrow-up"></i>
                    </div>
                    <el-button
                      v-if="superAdminDetail.superAdmin"
                      type="text"
                      size="mini"
                      class="bankCardBtn"
                      @click="setBank"
                      >更换</el-button
                    >
                  </div>
                </div>
                <div v-if="isShowStatus" class="bankCard">
                  <div>开户名称：{{ bankDetail.accountName }}</div>
                  <div>开户银行：{{ bankDetail.bankBranch }}</div>
                </div>
              </div>
              <div class="footer">
                <div class="footerL">
                  <div>账户总金额</div>
                  <div class="money">{{ accountsDetail.balanceAmount }}元</div>
                </div>
                <div class="footerC">
                  <div>冻结金额</div>
                  <div class="frozen">{{ accountsDetail.forzenAmount }}元</div>
                </div>
                <div class="footerR">
                  <el-button type="primary" @click="goIncomeList">收支明细</el-button>
                  <el-button class="bnt" @click="withdrawal">提现</el-button>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-card>

    <el-card class="box-card-order">
      <div slot="header" class="clearfix">
        <span class="title">功能服务</span>
      </div>
      <div class="orderContent">
        <div class="order_type">
          <div class="order_type_icon">
            <img src="../../../assets/college/Accounts/video.png" alt="" />
          </div>
          <div class="order_type_text">
            <div class="title">精选短视频</div>
            <div class="text">知识的精彩片段，让学员轻松学习</div>
            <div class="btn"><el-button type="primary">申请开通</el-button></div>
          </div>
        </div>
        <div class="order_type distance">
          <div class="order_type_icon">
            <img src="../../../assets/college/Accounts/live.png" alt="" />
          </div>
          <div class="order_type_text">
            <div class="title">直播课程</div>
            <div class="text">支持万人的稳定高质量课程直播功能</div>
            <div class="btn"><el-button type="primary">申请开通</el-button></div>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { getCookie } from '@/utils/cookies'
import { mapGetters } from 'vuex'
import to from 'await-to'
import { getAccountsMoney, getAccountsBankNew, getSearchSuperAdmin } from '@/api/college'
export default {
  data() {
    return {
      dialogVisible: false,
      formDetail: {
        price: '',
        radio: 1,
      },
      accountsDetail: {},
      bankDetail: {},
      superAdminDetail: {},
      userName: getCookie('userName'),
      isShowStatus: true,
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  created() {
    this.getAccountsMoneyData()
    this.getAccountsBankNewData()
    this.getSearchSuperAdminData()
  },
  methods: {
    async getAccountsMoneyData() {
      const [res, err] = await to(getAccountsMoney())
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.accountsDetail = res.data
    },
    async getAccountsBankNewData() {
      const [res, err] = await to(getAccountsBankNew({}))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.bankDetail = res.data
    },
    async getSearchSuperAdminData() {
      const [res, err] = await to(getSearchSuperAdmin())
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.superAdminDetail = res.data
    },
    isShowBank() {
      this.isShowStatus = !this.isShowStatus
    },
    setBank() {
      this.$router.push('setBankCard')
    },
    goIncomeList() {
      this.$router.push('/finance/incomeList')
    },
    withdrawal() {
      this.$router.push('/finance/incomeList')
    },
    changeAdmin() {
      this.$router.push('changeAdmin')
    },
  },
}
</script>

<style scoped lang="scss">
.box-card,
.box-card-order {
  margin-bottom: 30px !important;
}
.cardContent {
  .cardContentL {
    height: 216px;
    display: flex;
    justify-content: center;
    border-right: 1px dashed #e2e2e2;
    .avatar {
      border-radius: 50%;
      overflow: hidden;
      width: 80px;
      height: 80px;
      margin-right: 9px;
      img {
        @extend %width-height;
      }
    }
    .personInfo {
      display: flex;
      font-size: 16px;
      color: #333;
      cursor: pointer;
      padding-top: 30px;
      .nickname {
        margin-bottom: 10px;
      }
      .admin {
        color: #ff7b33;
        margin-top: 28px;
      }
    }
  }
  .cardContentR {
    height: 216px;
    padding-left: 36px;
    box-sizing: border-box;
    background: #fff5f0;
    .top {
      display: flex;
      align-items: center;
      padding-top: 30px;
      box-sizing: border-box;
      font-size: 14px;
      color: #606266;
      .bankCard {
        margin-left: 32px;
        ::v-deep .el-button {
          height: 28px;
        }
      }
    }
    .tops {
      padding-top: 30px;
      box-sizing: border-box;
      font-size: 14px;
      color: #606266;
      .bankCardNum {
        font-size: 18px;
        color: #333;
        display: flex;
        .arrowDown {
          margin-left: 10px;
          margin-right: 20px;
        }
      }
      .bankCard {
        transition: max-height 0.5s;
        display: flex;
        justify-content: center;
        flex-direction: column;
        width: 282px;
        height: 50px;
        background: #ffe8dc;
        border-radius: 4px;
        font-size: 12px;
        color: #909399;
        padding-left: 13px;
      }
    }
    .footer {
      display: flex;
      font-size: 14px;
      margin-top: 24px;
      color: #606266;
      .footerC {
        margin-left: 78px;
        margin-right: 75px;
      }
      .money {
        font-size: 22px;
        color: #ff7b33;
      }
      .frozen {
        font-size: 22px;
        color: #333;
      }
      .bnt {
        margin-left: 25px;
      }
    }
  }
}
.box-card-order {
  img {
    width: 247px;
    height: 154px;
  }
  .orderContent {
    display: flex;
    padding-bottom: 30px;
    .order_type {
      position: relative;
      color: #fff;
      .order_type_text {
        position: absolute;
        left: 36px;
        top: 0;
        ::v-deep .el-button {
          height: 28px;
          background: #ffffff;
          color: #a45b60;
          border-radius: 12px;
        }
        .title {
          font-size: 18px;
          font-weight: 700;
          margin-top: 25px;
          margin-bottom: 14px;
        }
        .text {
          margin-bottom: 28px;
        }
      }
    }
    .distance {
      margin-left: 16px;
    }
  }
}
</style>
